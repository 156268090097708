@import url(https://fonts.googleapis.com/css?family=Montserrat|Roboto);
.overview-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.election-container {
  width: 400px;
  border: 1px solid #f4f4f4;
}

.election-container header {
  background: #f4f4f4;
  padding: 1rem;
  text-align: center;
}

.election-container header h2 {
  font-size: 1.7rem;
}

.election-container__candidates-list {
  overflow-y: scroll;
  height: 500px;
  padding: 1rem;
}

.candidate-search {
  padding: .5rem;
}

.candidate-search input {
  width: 100%;
  padding: .5rem;
  border-radius: .5rem;
  border:1px solid #cccccc
}

.election-container__candidates-list li {
  list-style-type: none;
  padding: .5rem 0;
}

.candidate-container {
  width: 500px;
  display: block;
  margin: 0 auto;
  padding-top: 2rem;
}

.candidate-container header {
  display: flex;
}

.name-container {
  padding: .5rem 0 .5rem 2rem;
  color: #fff;
  width: 70%;
}

.party-logo-container {
  background: #f4f4f4;
  display: flex;
  text-align: center;
  align-items: center;
  width: 30%;
  padding: 1rem;
}

.party-logo-container img {
  max-width: 100%;
}

.name-container .first-name {
  font-size: 3rem;
}

.name-container .last-name {
  font-size: 3.2rem;
}

.name-container .last-name {
  font-weight: normal;
}

.vote-wrapper {
  background-color: #f4f4f4;
  width: 100%;
  padding: 1rem 0;
}

.vote-counter {
  background-color: #fff;
  width: 90%;
  color: #414a4b;
  margin: 0 auto;
  padding: 1rem;
  border-radius: .5rem;
}

.vote-counter__amount {
  text-align: center;
}
.vote-counter__amount__total {
  font-weight: bold;
  font-size: 4rem;
}

.vote-counter__amount__total-label {
  text-transform: uppercase;
  margin-left: .8rem;
}

.vote-disclaimer {
  font-size: 1.2rem;
  text-transform: none;
  margin-top: .5rem;
  display: block;
  text-align: left;
}

.vote-progress {
  text-transform: uppercase;

  width: 80%;
  margin: 2rem auto;
}

.vote-progress__bar {
  margin: 1.5rem 0;
  width: 100%;
  height: 2rem;
  background: lightgray;
  border-radius: .8rem;
}

.vote-progress__bar__bar {
  background-color: #414a4b;
  height: 100%;
  width: 0;
  border-radius: .8rem;
}

.vote-timestamp {
  font-size: 1.4rem;
}

.vote-timestamp__indicator {
  text-transform: none;
}

.okbe-logo-container {
  width: 100%;
  text-align: center;
}

.okbe-logo-container img {
  width: 40%;
}

.card-actions {
  display: flex;
  margin-top: 1.5rem;
  font-size: 1.4rem;
  justify-content: space-between;
  align-items: center;
}

.card-actions a.twitter {
  display: flex;
  align-items: center;
}

.twitter-logo-container {
  width: 2.2rem;
  display: inline-block;
  margin-right: .5rem;
}

.twitter-logo-container img {
  width: 100%;
}

/*
  Original: https://github.com/rstacruz/nprogress
  Replaced color by Open Knowledge green
*/


/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #5eb043;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #5eb043, 0 0 5px #5eb043;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #5eb043;
  border-left-color: #5eb043;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  width: 100%;
}

html, body {
  height: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#root {
  min-height: 100%;
  position: relative;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

p {
  line-height: 1.6;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 1.9rem;
}

a,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.twitter {
  color: #00aced!important;
}

