@import url('https://fonts.googleapis.com/css?family=Montserrat|Roboto');

html {
  box-sizing: border-box;
  font-size: 62.5%;
  width: 100%;
}

html, body {
  height: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#root {
  min-height: 100%;
  position: relative;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

p {
  line-height: 1.6;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 1.9rem;
}

a,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.twitter {
  color: #00aced!important;
}
