.candidate-container {
  width: 500px;
  display: block;
  margin: 0 auto;
  padding-top: 2rem;
}

.candidate-container header {
  display: flex;
}

.name-container {
  padding: .5rem 0 .5rem 2rem;
  color: #fff;
  width: 70%;
}

.party-logo-container {
  background: #f4f4f4;
  display: flex;
  text-align: center;
  align-items: center;
  width: 30%;
  padding: 1rem;
}

.party-logo-container img {
  max-width: 100%;
}

.name-container .first-name {
  font-size: 3rem;
}

.name-container .last-name {
  font-size: 3.2rem;
}

.name-container .last-name {
  font-weight: normal;
}

.vote-wrapper {
  background-color: #f4f4f4;
  width: 100%;
  padding: 1rem 0;
}

.vote-counter {
  background-color: #fff;
  width: 90%;
  color: #414a4b;
  margin: 0 auto;
  padding: 1rem;
  border-radius: .5rem;
}

.vote-counter__amount {
  text-align: center;
}
.vote-counter__amount__total {
  font-weight: bold;
  font-size: 4rem;
}

.vote-counter__amount__total-label {
  text-transform: uppercase;
  margin-left: .8rem;
}

.vote-disclaimer {
  font-size: 1.2rem;
  text-transform: none;
  margin-top: .5rem;
  display: block;
  text-align: left;
}

.vote-progress {
  text-transform: uppercase;

  width: 80%;
  margin: 2rem auto;
}

.vote-progress__bar {
  margin: 1.5rem 0;
  width: 100%;
  height: 2rem;
  background: lightgray;
  border-radius: .8rem;
}

.vote-progress__bar__bar {
  background-color: #414a4b;
  height: 100%;
  width: 0;
  border-radius: .8rem;
}

.vote-timestamp {
  font-size: 1.4rem;
}

.vote-timestamp__indicator {
  text-transform: none;
}

.okbe-logo-container {
  width: 100%;
  text-align: center;
}

.okbe-logo-container img {
  width: 40%;
}

.card-actions {
  display: flex;
  margin-top: 1.5rem;
  font-size: 1.4rem;
  justify-content: space-between;
  align-items: center;
}

.card-actions a.twitter {
  display: flex;
  align-items: center;
}

.twitter-logo-container {
  width: 2.2rem;
  display: inline-block;
  margin-right: .5rem;
}

.twitter-logo-container img {
  width: 100%;
}
